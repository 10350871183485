<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h1 class="text-2xl font-bold">Transaction Monitoring Portal - Two</h1>
      <!-- <div class=" flex justify-end items-end">
        <select
          class="p-3 mr-3 drop-shadow-2xl ring-1 border-blue-800 bg-white py-3 text-sm px-3 rounded-md mr-2"
          v-model="statusSelect"
        >
          <option value="">Today</option>
          <option value="rrn">RRN</option>
          <option value="stan">STAN</option>
          <option value="terminalId">Terminal ID</option>
        </select>
      </div> -->
      <div class="flex flex-wrap xl:-mx-5">
        <div class="w-full xl:my-5 xl:px-5 xl:w-1/3">
          <!-- Column Content -->
          <div class="drop-shadow-lg bg-white py-12 rounded-md">
            <p class="text-center font-semibold text-sm">Total Transactions</p>
            <h3 class="text-center font-bold text-2xl">
              {{ totalTransactions }}
            </h3>
          </div>
        </div>

        <div class="w-full xl:my-5 xl:px-5 xl:w-1/3">
          <!-- Column Content -->
          <div class="drop-shadow-lg bg-green-100 py-10 rounded-md">
            <p class="text-center font-semibold text-sm">
              Successful Transactions
            </p>
            <h3 class="text-center font-bold text-2xl">
              {{ successfulTransactions }}
            </h3>
            <p
              class="bg-transparent text-green-600 mt-2 text-xs font-bold text-center"
              @click="
                $router.push({
                  name: 'TransactionMonitoringPortalTwo',
                })
              "
            >
              View
            </p>
          </div>
        </div>

        <div class="w-full xl:my-5 xl:px-5 xl:w-1/3">
          <!-- Column Content -->
          <div class="drop-shadow-lg bg-red-100 py-10 rounded-md">
            <p class="text-center font-semibold text-sm">Failed Transactions</p>
            <h3 class="text-center font-bold text-2xl">
              {{ failedTransactions }}
            </h3>
            <p
              class="bg-transparent text-red-600 mt-2 text-xs font-bold text-center cursor-pointer"
              @click="
                $router.push({
                  name: 'TransactionMonitoringPortalFailedByRoute',
                })
              "
            >
              View
            </p>
          </div>
        </div>
      </div>
      <div class="mb-5 flex mt-20">
        <div class="w-2/3">Successful Transactions</div>
        <div class="float-right">
          <!-- <input
            class="p-3 drop-shadow-2xl ring-1 border-blue-800 bg-white text-sm rounded-md mr-2"
            v-model="statusSelect"
            placeholder="Search by bank, route"
          /> -->
          <button
            @click="filterToggle = !filterToggle"
            :class="
              filterToggle
                ? 'bg-blue-900 text-white'
                : 'border-blue-50 text-blue-800'
            "
            class="ring-1 inline-block uppercase shadow ml-2 text-xs font-bold text-white focus:shadow-outline focus:outline-none py-3 px-3 rounded float"
          >
            Filter By Date
          </button>
          <button
            @click="searchByToggle = !searchByToggle"
            :class="
              searchByToggle
                ? 'bg-blue-900 text-white'
                : 'bg-blue-50 text-blue-800'
            "
            class="ring-1 inline-block uppercase shadow ml-2 text-xs hover:text-blue-800 font-bold bg-blue-50 text-white hover:bg-blue-100 focus:shadow-outline focus:outline-none py-3 px-3 rounded float"
          >
            Filter by Time
          </button>
        </div>
      </div>
      <div class="my-4 flex bg-gray-100 p-5" v-if="searchByToggle">
        <div class="block">
          <label class="text-xs block font-bold">Time (From)</label>
          <input
            type="date"
            class="inline-block drop-shadow-2xl border-2 border-gray-100 bg-white py-2 px-5 rounded-md mr-2"
            name=""
            id=""
            placeholder="Date"
            v-model="dateData.starttime"
          />
        </div>
        <div class="block">
          <label class="text-xs block font-bold">Time (To)</label>
          <input
            type="date"
            class="inline-block drop-shadow-2xl border-2 border-gray-100 bg-white py-2 px-5 rounded-md mr-2"
            name=""
            id=""
            placeholder="Date"
            v-model="dateData.endtime"
          />
        </div>
        <div class="block">
          <button
            class="inline-block drop-shadow-2xl border-2 bg-blue-900 text-white py-2 px-3 mt-4 rounded-md mr-2"
            @click="searchFunc"
          >
            Search
          </button>
        </div>
      </div>
      <div class="my-4 flex bg-gray-100 p-5" v-if="filterToggle">
        <div class="block">
          <label class="text-xs block font-bold">Date</label>
          <input
            type="date"
            class="inline-block drop-shadow-2xl border-2 border-gray-100 bg-white py-2 px-5 rounded-md mr-2"
            name=""
            id=""
            placeholder="Date"
            v-model="dateData.startdatetime"
          />
        </div>
        <!-- <div class="block">
          <label class="text-xs block font-bold">Date (To)</label>
          <input
            type="date"
            class="inline-block drop-shadow-2xl border-2 border-gray-100 bg-white py-2 px-5 rounded-md mr-2"
            name=""
            id=""
            placeholder="Date"
            v-model="dateData.enddatetime"
          />
        </div> -->

        <div class="block">
          <button
            class="inline-block drop-shadow-2xl border-2 bg-blue-900 text-white py-2 px-3 mt-4 rounded-md mr-2"
            @click="fetchDatedRecords"
          >
            Search
          </button>
        </div>
      </div>
      <div class="mt-5" style="height: 500px; overflow: auto">
        <table class="shadow-lg bg-white w-full relative border-collapse">
          <thead>
            <tr>
              <th
                class="bg-blue-100 text-black text-xs whitespace-nowrap text-left px-8 py-2"
              >
                Bank
              </th>
              <th
                class="bg-blue-100 text-black text-xs whitespace-nowrap text-left px-8 py-2"
                colspan="3"
                @click="
                  $router.push({
                    name: 'ViewTransactionMonitoringByRoute',
                    params: {
                      id: 'NIBSS',
                    },
                  })
                "
              >
                <span
                  class="hover:bg-blue-200 bg-blue-50 rounded-md hover:drop-shadow-lg p-1 cursor-pointer"
                  >NIBSS Route</span
                >
              </th>
              <th
                class="bg-blue-100 text-black text-xs whitespace-nowrap text-left px-8 py-2"
                colspan="3"
                @click="
                  $router.push({
                    name: 'ViewTransactionMonitoringByRoute',
                    params: {
                      id: 'KIMONO',
                    },
                  })
                "
              >
                <span
                  class="hover:bg-blue-200 bg-blue-50 rounded-md hover:drop-shadow-lg p-1 cursor-pointer"
                  >Kimono Route</span
                >
              </th>
              <th
                class="bg-blue-100 text-black text-xs border whitespace-nowrap text-left px-8 py-2 cursor-pointer"
                colspan="3"
                @click="
                  $router.push({
                    name: 'ViewTransactionMonitoringByRoute',
                    params: {
                      id: 'UP',
                    },
                  })
                "
              >
                <span
                  class="hover:bg-blue-200 bg-blue-50 rounded-md hover:drop-shadow-lg p-1 cursor-pointer"
                  >Up Route</span
                >
              </th>
            </tr>
            <tr>
              <th
                class="text-white text-sm whitespace-nowrap text-left px-8 py-2"
              ></th>
              <th class="text-xs whitespace-nowrap text-left px-8 py-2">
                Mastercard
              </th>
              <th class="text-xs whitespace-nowrap text-left px-8 py-2">
                Visa
              </th>
              <th class="text-xs whitespace-nowrap text-left px-8 py-2">
                Verve
              </th>
              <th class="text-xs whitespace-nowrap text-left px-8 py-2">
                Mastercard
              </th>
              <th class="text-xs whitespace-nowrap text-left px-8 py-2">
                Visa
              </th>
              <th class="text-xs whitespace-nowrap text-left px-8 py-2">
                Verve
              </th>
              <th class="text-xs whitespace-nowrap text-left px-8 py-2">
                Mastercard
              </th>
              <th class="text-xs whitespace-nowrap text-left px-8 py-2">
                Visa
              </th>
              <th class="text-xs whitespace-nowrap text-left px-8 py-2">
                Verve
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(transaction, i) in monitoringData"
              :key="i"
              class="cursor-pointer"
            >
              <td
                class="bostartdatetimerder px-8 py-3 whitespace-nowrap text-xs"
                @click="
                  $router.push({
                    name: 'ViewTransactionMonitoringByBank',
                    params: {
                      id: transaction.bank,
                    },
                  })
                "
              >
                {{ transaction.bank.replaceAll("_", " ") || "N/A" }}
              </td>
              <td
                class="border px-8 py-3 whitespace-nowrap text-xs"
                :class="
                  formatPercentage(
                    transaction.nMASTERCARD === undefined
                      ? 0
                      : transaction.nMASTERCARD
                  )
                "
              >
                {{
                  (transaction.nMASTERCARD &&
                    transaction.nMASTERCARD.trim() !== "N/A" &&
                    transaction.nMASTERCARD + "%") ||
                  "N/A"
                }}
              </td>
              <td
                class="border px-8 py-3 whitespace-nowrap text-xs"
                :class="
                  formatPercentage(
                    transaction.nVISA === undefined ? 0 : transaction.nVISA
                  )
                "
              >
                {{
                  (transaction.nVISA &&
                    transaction.nVISA.trim() !== "N/A" &&
                    transaction.nVISA + "%") ||
                  "N/A"
                }}
              </td>
              <td
                class="border px-8 py-3 whitespace-nowrap text-xs"
                :class="
                  formatPercentage(
                    transaction.nVERVE === undefined ? 0 : transaction.nVERVE
                  )
                "
              >
                {{
                  (transaction.nVERVE &&
                    transaction.nVERVE.trim() !== "N/A" &&
                    transaction.nVERVE + "%") ||
                  "N/A"
                }}
              </td>
              <td
                class="border px-8 py-3 whitespace-nowrap text-xs"
                :class="
                  formatPercentage(
                    transaction.kMASTERCARD === undefined
                      ? 0
                      : transaction.kMASTERCARD
                  )
                "
              >
                {{
                  (transaction.kMASTERCARD &&
                    transaction.kMASTERCARD.trim() !== "N/A" &&
                    transaction.kMASTERCARD + "%") ||
                  "N/A"
                }}
              </td>
              <td
                class="border px-8 py-3 whitespace-nowrap text-xs"
                :class="
                  formatPercentage(
                    transaction.kVISA === undefined ? 0 : transaction.kVISA
                  )
                "
              >
                {{
                  (transaction.kVISA &&
                    transaction.kVISA.trim() !== "N/A" &&
                    transaction.kVISA + "%") ||
                  "N/A"
                }}
              </td>
              <td
                class="border px-8 py-3 whitespace-nowrap text-xs"
                :class="
                  formatPercentage(
                    transaction.kVERVE === undefined ? 0 : transaction.kVERVE
                  )
                "
              >
                {{
                  (transaction.kVERVE &&
                    transaction.kVERVE.trim() !== "N/A" &&
                    transaction.kVERVE + "%") ||
                  "N/A"
                }}
              </td>
              <td
                class="border px-8 py-3 whitespace-nowrap text-xs"
                :class="
                  formatPercentage(
                    transaction.uMASTERCARD === undefined
                      ? 0
                      : transaction.uMASTERCARD
                  )
                "
              >
                {{
                  (transaction.uMASTERCARD &&
                    transaction.uMASTERCARD.trim() !== "N/A" &&
                    transaction.uMASTERCARD + "%") ||
                  "N/A"
                }}
              </td>
              <td
                class="border px-8 py-3 whitespace-nowrap text-xs"
                :class="
                  formatPercentage(
                    transaction.kVISA === undefined ? 0 : transaction.kVISA
                  )
                "
              >
                {{
                  (transaction.kVISA &&
                    transaction.kVISA.trim() !== "N/A" &&
                    transaction.kVISA + "%") ||
                  "N/A"
                }}
              </td>
              <td
                class="border px-8 py-3 whitespace-nowrap text-xs"
                :class="
                  formatPercentage(
                    transaction.kVERVE === undefined ? 0 : transaction.kVERVE
                  )
                "
              >
                {{
                  (transaction.kVERVE &&
                    transaction.kVERVE.trim() !== "N/A" &&
                    transaction.kVERVE + "%") ||
                  "N/A"
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      isDisabled: false,
      search: "",
      accounts: null,
      filterToggle: false,
      searchByToggle: false,
      dateSelect: null,
      statusSelect: "",
      searchByISAName: "",
      searchByISAID: "",
      fromDateSelect: "",
      toDateSelect: "",
      currentPage: 1,
      dateData: null,
      transactionMonitoring: [],
      fetchedData: [],
      monitoringData: [],
      totalTransactions: 0,
      successfulTransactions: 0,
      failedTransactions: 0,
    };
  },
  computed: {},
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async created() {
    // this.isLoading = true;
    const d = new Date();
    this.dateData = {
      // startdatetime: new Date(d.setDate(d.getDate())).toJSON().split("T")[0],
      startdatetime: new Date(d.setDate(d.getDate() - 1))
        .toJSON()
        .split("T")[0],
      // .split("T")[0],
      enddatetime: new Date().toJSON().split("T")[0],
      startTime: "00:00",
      endTime: this.moment(d).format("HH:mm"),
    };
    // this.fetchMonitoringPortal();

    // this.fetchUsingAxios();
    this.fetchUsingFetch();
  },
  mounted() {},
  methods: {
    formatPercentage(percentage) {
      if (percentage <= 29) {
        return "font-medium text-red-600";
      } else if (percentage <= 79) {
        return "font-medium text-amber";
      } else if (percentage <= 100) {
        return "font-medium text-green-600";
      } else {
        return "font-medium text-red-600";
      }
    },
    transformData(data) {
      // let xdata = this.content.views[0].view;
      let xdata = data;
      let banksNibss = [];
      xdata.forEach((el) => {
        if (el.route === "NIBSS") {
          banksNibss.push({
            bank: el.bank,
            ["n" + el.card]: el.successRate,
          });
        }
      });

      const result = banksNibss.reduce((acc, value) => {
        const fIndex = acc.findIndex((v) => v.bank === value.bank);
        if (fIndex >= 0) {
          acc[fIndex] = { ...acc[fIndex], ...value };
        } else {
          acc.push(value);
        }
        return acc;
      }, []);

      // console.log(result);

      let banksUP = [];
      xdata.forEach((el) => {
        if (el.route === "UP") {
          banksUP.push({
            bank: el.bank,
            ["u" + el.card]: el.successRate,
            // route: el.route,
          });
        }
      });

      const resultUP = banksUP.reduce((acc, value) => {
        const fIndex = acc.findIndex((v) => v.bank === value.bank);
        if (fIndex >= 0) {
          acc[fIndex] = { ...acc[fIndex], ...value };
        } else {
          acc.push(value);
        }
        return acc;
      }, []);

      const allBanks = [...result, ...resultUP];

      const finalResult = allBanks.reduce((acc, value) => {
        const fIndex = acc.findIndex((v) => v.bank === value.bank);
        if (fIndex >= 0) {
          acc[fIndex] = { ...acc[fIndex], ...value };
        } else {
          acc.push(value);
        }
        return acc;
      }, []);

      this.monitoringData = finalResult;
      // console.log(finalResult);
    },
    async fetchDatedRecords() {
      let date = this.dateData.startdatetime;
      let route = this.$route.params.id;
      let myHeaders = new Headers();
      // myHeaders.append("Access-Control-Allow-Origin", "*");
      // myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
        withCredentials: true,
      };

      try {
        this.isLoading = true;
        fetch(
          `https://34.204.53.88:8084/v1/reporting/home?date=${date}`,
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => {
            this.isLoading = false;
            const res = JSON.parse(result);
            this.fetchedData = res.content.views[0].view;
            this.transformData(this.fetchedData);
            this.successfulTransactions = this.fetchedData.length;
            this.failedTransactions = res.content.views[1].view.length;
            this.totalTransactions =
              parseInt(this.successfulTransactions, 10) +
              parseInt(this.failedTransactions, 10);
          })
          .catch((error) => console.log("error", error));
      } catch (error) {}
    },
    async searchFunc() {
      return;
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    async fetchUsingFetch() {
      var myHeaders = new Headers();
      // myHeaders.append("Access-Control-Allow-Origin", "*");
      // myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
        withCredentials: true,
      };

      try {
        this.isLoading = true;

        fetch("https://34.204.53.88:8084/v1/reporting/home", requestOptions)
          .then((response) => response.text())
          .then((result) => {
            this.isLoading = false;
            const res = JSON.parse(result);
            this.fetchedData = res.content.views[0].view;
            this.transformData(this.fetchedData);
            this.successfulTransactions = this.fetchedData.length;
            this.failedTransactions = res.content.views[1].view.length;
            this.totalTransactions =
              parseInt(this.successfulTransactions, 10) +
              parseInt(this.failedTransactions, 10);
            console.log(res);
          })
          .catch((error) => console.log("error", error));
      } catch (error) {}
    },
  },
};
</script>

<style scoped>
.text-amber {
  color: #d08411;
}
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(241, 241, 241);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th:first-child {
  /* background: #dee6f2; */
  color: #333;
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
